<mat-form-field appearance="outline">
  <mat-label>{{placeholder}}</mat-label>
  <mat-chip-list #chipList
                 aria-label="Ship list">
    <mat-chip *ngFor="let selectedValue of value"
              data-test-id="itemChip"
              (removed)="remove(selectedValue)">
      <span class="text-truncate"
            [title]="selectedValue">
        {{ getItemLabelById(selectedValue) }}
      </span>
      <ng-container *ngIf="isCancelDisabled">
        <button [ngClass]="{'disableCancelBtn' : disableCancelSelected(getItemLabelById(selectedValue))}"
                matChipRemove
                data-test-id="removeItemBtn">
          <mat-icon>cancel</mat-icon>
        </button>
      </ng-container>
      <button *ngIf="!isCancelDisabled" 
              matChipRemove
              data-test-id="removeItemBtn">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input #searchInput
           data-test-id="autocompleteInput"
           [placeholder]="placeholder"
           [formControl]="innerCtrl"
           [hidden]="innerDisabled"
           [matAutocomplete]="auto"
           [matChipInputFor]="chipList"
           [matAutocompleteConnectedTo]="autocompleteOrigin"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>

  <mat-autocomplete #auto="matAutocomplete"
                    multiple="true"
                    (optionSelected)="selected($event)">
    <mat-option *ngIf="canAdd && innerCtrl.value"
                data-test-id="createItemChip"
                [value]="innerCtrl.value">
      <span>{{innerCtrl.value}}</span>
    </mat-option>

    <ng-container *ngIf="filteredItems$ | async as filteredItems">

      <mat-option *ngIf="!canAdd && filteredItems.length === 0"
                  data-test-id="noResultsChip"
                  [disabled]="true">
        {{ this.useServerSideSearch
              ? innerCtrl.value.length >= this.filterThreshold
                      ? 'Your filter criteria did not match any results.'
                      : 'Please type at least ' + filterThreshold + ' letter(s)'
              : 'Your filter criteria did not match any results.'
        }}
      </mat-option>

      <mat-option *ngFor="let item of filteredItems"
                  data-test-id="selectItemBtn"
                  class="px-0"
                  [value]="item">
        <div *ngIf="multiselect"
             (click)="optionClicked($event, item);">
          <mat-checkbox class="w-100"
                        (change)="toggleSelection(item)"
                        (click)="$event.stopPropagation()">
            {{ getItemLabel(item) }}
          </mat-checkbox>
        </div>

        <div *ngIf="!multiselect"
             (click)="optionClicked($event, item);"
             class="mx-16 text-truncate">
          {{ getItemLabel(item) }}
        </div>
      </mat-option>

    </ng-container>

  </mat-autocomplete>
</mat-form-field>
