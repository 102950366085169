<ng-container *ngIf="{
  step: step$ | async
} as data">


  <ng-container [ngSwitch]="data.step">
    <apx-ui-shared-dialog *ngSwitchCase="'Check'">

      <ng-container header>
        <h2 data-test-id="title">Suspend {{dialogData.data.PauseType}}</h2>
      </ng-container>
    
      <ng-container body>
    
        <form [formGroup]="form">
          <div fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="space-between end">
    
            <mat-form-field fxFlex="50"
                            appearance="outline">
              <mat-label>Start</mat-label>
              <input matInput
                    autocomplete="off"
                    formControlName="startDate"
                    (dateChange)="onStartDateChange()"
                    [matDatepickerFilter]="startDateSuspendFilter"
                    [matDatepicker]="start">
              <mat-datepicker-toggle matSuffix
                                    [for]="start">
              </mat-datepicker-toggle>
              <mat-datepicker #start>
              </mat-datepicker>
            </mat-form-field>
    
            <div fxFlex="50">
              <mat-checkbox formControlName="endAtEnable"
                            data-test-id="endAtEnable"
                            (click)="onEndDateChange()">
                End
              </mat-checkbox>
    
              <mat-form-field appearance="outline">
                <mat-label>End</mat-label>
                <input matInput
                      formControlName="endDate"
                      autocomplete="off"
                      (dateChange)="onEndDateChange()"
                      [min]="minBackDate"
                      [matDatepicker]="end">
                <mat-datepicker-toggle matSuffix
                                      [for]="end">
                </mat-datepicker-toggle>
                <mat-datepicker #end
                                [disabled]="!endAtEnable.value">
                </mat-datepicker>
              </mat-form-field>
            </div>
          </div>
    
          <apx-ui-shared-reason-input formControlName="reason"
                                      [reasons]="reasons"
                                      [displayComment]="false">
          </apx-ui-shared-reason-input>
        </form>
    
        <p><strong>Summary:</strong> Suspend will start on {{ getStartDate.value | date: 'MM/dd/yyyy' }}
          and
          <span *ngIf="endAtEnable.value; else indefinitely">end on {{ getEndDate.value | date: 'MM/dd/yyyy' }}</span>
          <ng-template #indefinitely>continue indefinitely</ng-template>.
        </p>
    
        <ng-container *ngIf="form.getError('invalidDate')">
          <span class="mat-error">
            Invalid start and and dates.
          </span>
        </ng-container>
    
      </ng-container>
    
      <ng-container footer>
    
        <div>
          <button mat-stroked-button
                  class="mr-auto"
                  type="button"
                  data-test-id="cancelBtn"
                  [mat-dialog-close]="false">
            Cancel
          </button>
    
          <button mat-flat-button
                  type="button"
                  color="primary"
                  data-test-id="confirmBtn"
                  [disabled]="form.pristine || form.disabled || form.invalid"
                  (click)="onSubmit()">
            Submit
          </button>
        </div>
    
      </ng-container>
    
    </apx-ui-shared-dialog>

    <apx-ui-shared-dialog *ngSwitchCase="'Confirm'">
      <ng-container body>
        <div fxLayout="column"
            fxLayoutGap="16px">
          <span>Pending order(s) currently exists. Please select desired action.</span>
          <mat-radio-group fxLayout="column"
                          fxLayoutGap="10px"
                          class="pl-20"
                          [formControl]="decisionCtrl">
            <mat-radio-button color="primary"
                              class="mr-32"
                              data-test-id="well"
                              [value]="true">
              Suspend and KEEP pending order(s)
            </mat-radio-button>
            <mat-radio-button color="primary"
                              data-test-id="non-well"
                              class="mr-32"
                              [value]="false">
              Suspend and CANCEL pending order(s)
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>

      <ng-container footer>

        <div>
          <button mat-stroked-button
                  class="mr-auto"
                  type="button"
                  data-test-id="cancelBtn"
                  [mat-dialog-close]="false">
            Close
          </button>

          <button mat-flat-button
                  type="button"
                  color="primary"
                  data-test-id="confirmBtn"
                  [disabled]="decisionCtrl.invalid"
                  (click)="onConfirm()">
            Confirm
          </button>
        </div>

      </ng-container>
    </apx-ui-shared-dialog>
  </ng-container>
</ng-container>
