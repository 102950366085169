import { ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatAutocompleteOrigin, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { noop, Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';

interface ParentOf<T> {
  [key: string]: T;
}

@Component({
  selector: 'apx-ui-shared-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent<T extends Record<string, unknown> | string>
  implements ControlValueAccessor, Validator, OnInit, OnChanges {

  /**
   * Holds placeholder.
   */
  @Input() placeholder = 'Start typing to filter results...';

  /**
   * Holds filter threshold.
   */
  @Input() filterThreshold = 3;

  /**
   * Holds items.
   */
  @Input() useServerSideSearch = false;

  /**
   * Holds items.
   */
  @Input() items: Array<T | ParentOf<T> | any> = [];

  /**
   * Holds items.
   */
  @Input() selectedItems: Array<T | ParentOf<T>> = [];

  /**
   * Holds value field key.
   */
  @Input() bindValue?: string;

  /**
   * Holds label field key.
   */
  @Input() bindLabel?: string;

  /**
   * Holds an event when new value.
   */
  @Input() canAdd?: boolean;

  /**
   * Holds can add flag.
   */
  @Input() multiselect = false;

  @Input() isCancelDisabled = false;

  @Input() prevSelectedData?: any;

  /**
   * Emits an event when value changes.
   */
  @Output() addNew = new EventEmitter<string>();

  /**
   * Emits an event when value changes.
   */
  @Output() valueChange = new EventEmitter<T[]>();

  /**
   * Emits an event when autocomplete text changes.
   */
  @Output() autocompleteTextChange = new EventEmitter<string>();

  @ViewChild(MatAutocompleteOrigin) autocompleteOrigin: MatAutocompleteOrigin;
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  innerCtrl: FormControl;

  separatorKeysCodes: number[] = [ENTER];
  innerValue: T[] = [];

  filteredItems$: Observable<Array<T | ParentOf<T>>>;
  filterr: Array<T | ParentOf<T>>;

  innerDisabled = false;

  /**
   * Holds form.
   */
  private changeFn: (v: T[]) => void = noop;
  private touchedFn: () => void = noop;

  /**
   *  Constructor
   */
  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }
  /**
   * Sets the disabled state for non-form use
   */
  @Input() set disabled(v: boolean | string) {
    const newValue = v === true || v === 'true';
    if (this.innerDisabled === newValue) {
      return;
    }
    this.innerDisabled = newValue;
    if (this.innerCtrl) {
      this.setDisabledState(this.innerDisabled);
    }
  }

  /**
   * Sets the value
   */
  @Input() set value(v: T[]) {
    if (this.innerValue === v) {
      return;
    }
    this.innerValue = [...v];
  }

  get value(): T[] {
    return this.innerValue;
  }

  /**
   * On Init.
   */
  ngOnInit(): void {
    this.innerCtrl = new FormControl({
      value: '',
      disabled: this.innerDisabled,
    });

    this.setFilteredItems();
  }

  /**
   * On Changes
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.items && this.items) {
      this.setFilteredItems();
    }

  }

  /**
   * Write value.
   */
  writeValue(value: T[]): void {
    this.value = value ? value : [];
    this.changeDetectorRef.detectChanges();
    this.setFilteredItems();
  }

  /**
   * Register on change.
   */
  registerOnChange(fn: any): void {

    this.changeFn = fn;

  }

  /**
   * Register on touched.
   */
  registerOnTouched(fn: any): void {

    this.touchedFn = fn;

  }

  /**
   * Set disabled state.
   */
  setDisabledState(isDisabled: boolean): void {

    if (isDisabled) {
      this.innerCtrl.disable();
    } else {
      this.innerCtrl.enable();
    }

    this.innerDisabled = isDisabled;

  }

  /**
   * Validate.
   */
  validate(): ValidationErrors | null {

    return this.innerCtrl.valid
      ? null
      : {
        AcquiaLiftUIMultiSelectComponentValidator: true,
      };

  }

  /**
   * Add
   */
  add(event: MatChipInputEvent): void {

    const value = (event.value || '').trim();

    if (value) {
      this.addNew.emit(value);
    }

  }

  /**
   * Selected
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    this.addNewItem(event.option.value as T);
  }

  /**
   * Remove
   */
  remove(value: T): void {

    const index = this.innerValue.indexOf(value);

    if (index >= 0) {
      this.innerValue.splice(index, 1);

      this.touchedFn();
      this.changeFn(this.innerValue);
      this.valueChange.emit(this.innerValue);
      this.setFilteredItems();
    }

  }

  /**
   * Get item value
   */
  getItemValue(item: T | ParentOf<T>): T {

    return this.bindValue ? (item as ParentOf<T>)[this.bindValue] as T : item as T;

  }

  /**
   * Get item label
   */
  getItemLabelById(value: T): string {

    return this.getItemLabel(this.getItemByValue(value));

  }

  /**
   * Get item by value
   */
  getItemByValue(id: T): T | ParentOf<T> {

    return this.bindValue
      ? (this.useServerSideSearch
      ? this.selectedItems
      : this.items)?.find(i => this.getItemValue(i) === id) ?? { [this.bindValue]: id }
      : id;

  }

  /**
   * Get item label
   */
  getItemLabel(item: T | ParentOf<T>): string {

    return !item
      ? ''
      : (this.bindLabel
        ? (item as ParentOf<T>)[this.bindLabel] as T
        : item as T)?.toString()
      ?? (this.bindValue
        ? (item as ParentOf<T>)[this.bindValue] as T
        : item as T)?.toString()
      ?? '';

  }

  /**
   * Add new tag
   */
  addNewItem(item: T | ParentOf<T>): void {

    const sanitizedValue = this.getItemValue(item);
    if (this.innerValue.every(t => t !== sanitizedValue)) {
      if (this.useServerSideSearch) {
        this.selectedItems.push(item);
      }

      this.innerValue.push(sanitizedValue);

      this.touchedFn();
      this.changeFn(this.innerValue);
      this.valueChange.emit(this.innerValue);
      this.setFilteredItems();

    }

  }

  /**
   * Set filtered items
   */
  setFilteredItems(): void {
    const executeFiltering = (f: string | Record<string, unknown> | any[]): Array<T | ParentOf<T>> => {
      const sanitizedValue = typeof f === 'string' ? f : '';

      return this.useServerSideSearch
        ? (sanitizedValue.length >= this.filterThreshold ? this.items : [])
        : this.filter(sanitizedValue);
    };

    this.filteredItems$ = this.innerCtrl?.valueChanges.pipe(
      filter(f => typeof f === 'string'),
      tap(f => {
        if (this.useServerSideSearch && f.length >= this.filterThreshold) {
          this.autocompleteTextChange.emit(f);
        }
      }),
      map(executeFiltering),
      startWith(executeFiltering(this.innerCtrl?.value)),
    );

  }

  /**
   * Filter
   */
  private filter(value: string | undefined): Array<T | ParentOf<T>> {

    const filterValue = value?.toLowerCase();

    return this.items
        ?.filter(item => {

          return this.value.length === 0
            || typeof this.getItemValue(item) === 'object' && !this.value.some( v => v['id'] === item.id)
            || ((typeof this.getItemValue(item) === 'object' && !('id' in this.value[0])
            || typeof this.getItemValue(item) !== 'object') && !this.value.includes(this.getItemValue(item)));
          },
        )
        ?.filter(item => !filterValue || this.getItemLabel(item).toLowerCase().indexOf(filterValue) >= 0)
      ?? [];

  }

  optionClicked(event: MouseEvent, item): void {
    event.stopPropagation();
    this.toggleSelection(item);
  }

  toggleSelection(item): void {
    this.addNewItem(item);
  }

  disableCancelSelected(value): boolean {
    const rec = this.prevSelectedData.find(obj => obj===value);
    return rec ? true : false;
  }
}
