import { AbstractModel } from '@apx-ui/apx-core';

import { Account, ACCOUNT_TYPES } from '../interfaces';

export class AccountModel extends AbstractModel<Account> {

  constructor(protected override data: Account) {
    super(data);
  }

  getId(): string {
    return this.data.Id;
  }

  getAccountRoles(): string[] {
    return this.data.AccountRoles;
  }

  getName(): string {
    return this.data.CustomerName;
  }

  getAccountType(): ACCOUNT_TYPES {
    return this.data.AccountType;
  }

  getAccountManager(): string {
    return this.data.AccountManager;
  }

  getSoldTo(): string | null {
    return this.data.SoldTo ? `${this.data.SoldTo}` : null;
  }

  getSoldToNumber(): number {
    return this.data.SoldTo || 0;
  }

  getAccountStatus(): string {
    return this.data.AccountStatus;
  }

  getPlantId(): string {
    return this.data.PlantID;
  }

  getRDP(): string {
    return `${this.data.RegionName} ᛫ ${this.data.DistrictName} ᛫ ${this.data.PlantName} (${this.data.PlantID})`;
  }

  getAccountHeader(): string {
    return `${this.getAccountType()} ᛫ ${this.getPlantName()}`;
  }

  getCrewId(): number {
    return this.data.CrewId;
  }

  getPlantName(): string {
    return this.data.PlantName;
  }

  getDistrictName(): string {
    return this.data.DistrictName;
  }

  getRegionName(): string {
    return this.data.RegionName;
  }

}
