export enum GridViewType {
  Editable = 'editable-grid',
  ReportEditable = 'editable-report-grid',
  ReportEditableDelivery = 'editable-delivery-report-grid',
  ReportReadOnly = 'read-only-report-grid',
  ReportSelectRowCount = 'select-row-count',
  ReportPagination = 'pagination'
}

export type gridType = 'SAMPLES' | 'TANKS';
